import { LoadingOverlay } from '@mantine/core'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { useFetch } from '@/lib/hooks'
import { Channel as ChannelType, Site as SiteType } from '@/types'

import { FindChannel, SelectChannel, Static } from './components'

interface Data {
  site?: SiteType
  channel?: ChannelType
}

interface Props {
  initialData?: Data
}

export default function SiteView({ initialData }: Props) {
  // Hooks
  const router = useRouter()

  // Constants
  const { site: siteSlug } = router.query || {}

  // Fetch
  const { data: siteData, error } = useFetch([siteSlug ? `/${siteSlug}/` : null], {
    fallbackData: initialData?.site // rehydration if necessary
  })

  // Constants
  const isLoading = !siteData && !error

  // Effects
  useEffect(() => {
    if (siteData?.home === 'main-channel' && siteData?.mainChannel?.slug) {
      router.push(`/${siteData?.mainChannel?.slug}`)
    }
    if (siteData?.home === 'redirect' && siteData?.redirectTo && typeof window !== 'undefined') {
      window.location.href = siteData.redirectTo
    }
  }, [siteData, router])

  // Components
  const renderHome = () => {
    switch (siteData?.home) {
      case 'redirect':
        return <LoadingOverlay visible loaderProps={{ variant: 'bars' }} />
      case 'main-channel':
        return <LoadingOverlay visible />
      case 'static':
        return <Static siteData={siteData} loading={isLoading} />
      case 'select-channel':
        return <SelectChannel siteData={siteData} />
      case 'find-channel':
        return <FindChannel siteData={siteData} />
      default:
        return null
    }
  }

  // Render
  return !siteData && !error ? <LoadingOverlay visible /> : renderHome()
}
